<template>
  <OnboardingStep
    :title="$t('onboarding.fivestarsSetupView.title')"
    :next-text="$t('onboarding.fivestarsSetupView.nextText')"
    v-bind="$props"
  >
    <FivestarsView v-bind="$props" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import FivestarsView from "../Settings/AutomationView/FivestarsView";

export default {
  name: "FivestarsSetupView",
  components: { FivestarsView },
  extends: BaseStepView,
};
</script>
